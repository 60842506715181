<template>
  <div class="container">
    <PublicHeader :totalName="$t('exchange').title" />
    <div class="content">
      <img src="@/assets/img/ex_le.png" width="7" />
      <div class="account">
        <div class="txt">{{ $t('exchange').follow }}<span>{{ $t('exchange').fundAccount }}</span></div>
        <div class="line"></div>
        <div class="txt">{{ $t('exchange').follow }}<span>{{ $t('exchange').fundAccount }}</span></div>
      </div>
      <div class="icoss">
        <img src="@/assets/img/exchange_d.png" width="24" />
      </div>
    </div>
    <div class="label">{{ $t('exchange').curreny }}</div>
    <div class="coint" @click="showPicker = true"><span>{{ BuyCurrency }}</span><van-icon name="arrow" size="18"
        color="#333333" />
    </div>
    <div class="label">{{ $t('exchange').buyAmount }}</div>
    <van-field v-model="BuyAmount" :placeholder="$t('exchange').buyAmountMsg"></van-field>
    <div class="msgs">{{ $t('exchange').canAmount }}：{{ CanAmount }} <span>{{ PayCurrency }} </span></div>
    <div class="msgs">{{ $t('exchange').needAmount }}：{{ NeedAmount }} <span>{{ PayCurrency }} </span></div>
    <!-- 按钮 -->
    <van-button color="#C92B2E" block style="border-radius: 10px;height: 50px;margin-top: 100px;" @click="preToPass">{{
      $t('public').confirm }}</van-button>
    <!-- 选择币种 -->
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="onConfirm"
        :confirm-button-text="$t('public').confirm" :cancel-button-text="$t('public').cancel" />
    </van-popup>
    <!-- 输入密码 -->
    <van-popup v-model="showPassword" closeable round :style="{ width: '93%', borderRadius: '10px' }">
      <div class="popInfo">
        <div class="title"> {{ $t('public').Paypassword }}</div>
        <div class="password">
          <van-password-input :value="Password" :length="6" :gutter="5" :focused="showKeyboard"
            @focus="showKeyboard = true" />
        </div>
        <div class="pwstips">{{ $t('public').PaypasswordMsg }}</div>
        <div class="pwsbtn" @click="CheckFundPassword">{{ $t('public').confirm }}</div>
      </div>
      <!-- 数字键盘 -->
      <van-number-keyboard v-model="Password" :show="showKeyboard" @blur="showKeyboard = false" />
    </van-popup>
  </div>

</template>

<script>
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
import PublicHeader from '@/components/publicHeader'

export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      userInfo: {},
      Password: '',
      showPassword: false,
      showKeyboard: false,
      showPicker: false,
      columns: ['USDT', 'BTC', 'ETH', 'TRX', 'USDC', 'JiaShengTOKEN'],
      CanAmount: '',
      NeedAmount: 0,
      BuyAmount: '',
      PayCurrency: '',
      BuyCurrency: 'USDT',
      BuyPrice: 1,
      PayPrice: 1,
    }
  },
  watch: {
    BuyAmount() {
      if (this.BuyAmount) {
        this.changeRate()
      }
    },
    Password() {
      if (this.Password.length == 6)
        this.showKeyboard = false
    }
  },
  computed: {
    ...mapGetters(['basicConfig', 'connection', 'baseMsg']),
  },
  mounted() {
    if (this.$route.query.code) {
      this.PayCurrency = this.$route.query.code
      this.columns = this.columns.filter(p => p != this.PayCurrency)
      if (this.PayCurrency == 'USDT')
        this.BuyCurrency = 'BTC'
      this.GetLegalWalletsBalance(this.PayCurrency)
      this.signalRStart()
      this.getAccountBase()
    }
  },
  methods: {
    // 获取用户信息
    async getAccountBase() {
      const res = await userApi.getAccountBase()
      this.userInfo = res.Data
    },
    //推送
    signalRStart() {
      let that = this
      const connection = this.connection
      let time = setInterval(() => {
        if (connection.connectionState === 'Connected') {
          clearInterval(time)
          time = null
          connection.invoke('SendMsg', 'product')
        }
      }, 100)
      //最新价格
      connection.on('secondPrices', function (res) {
        res.forEach((item) => {
          if (item.Code === that.BuyCurrency)
            that.BuyPrice = item.Price
          if (item.Code === that.PayCurrency)
            that.PayPrice = item.Price
        })
      })
    },
    //交易密码预检
    preToPass() {
      if (!this.BuyAmount) {
        this.$toast(this.$t('exchange').buyAmountMsg)
        return false
      }
      if (this.NeedAmount > this.CanAmount) {
        this.$toast(this.$t('exchange').noAmount)
        return false
      }
      //是否设置交易密码
      if (!this.userInfo.BindFundPassword) {
        this.$dialog.alert({
          title: this.$t('public').noPayPasswordMsg,
          confirmButtonText: this.$t('public').confirm,
        })
          .then(() => {
            this.$router.push({ name: 'SetPayPwd' })
          })
        return false
      }
      this.showPassword = true
      this.showKeyboard = true
    },
    //校验密码
    async CheckFundPassword() {
      if (this.Password.length < 6) {
        this.$toast(this.$t('public').PaypasswordMsg)
        return
      }
      this.Password = this.Password.slice(0, 6)
      await userApi.CheckFundPassword({ Str: this.Password })
      this.handleSub()
    },
    // 提交
    async handleSub() {
      const form = {
        PayCurrency: this.PayCurrency,
        BuyCurrency: this.BuyCurrency,
        BuyAmount: this.BuyAmount,
      }
      await userApi.SwapExchange(form)
      this.$toast(this.$t('exchange').subMsg)
      this.showPassword = false
      this.showKeyboard = false
      this.BuyAmount = ''
      this.Password = ''
      this.$router.go(-1)
    },
    //汇率计算
    changeRate() {
      this.NeedAmount = (this.BuyPrice / this.PayPrice * this.BuyAmount).toFixed(6)
    },
    //获取资金账户余额
    async GetLegalWalletsBalance(val) {
      const res = await userApi.GetLegalWalletsBalance({ CoinType: val })
      this.CanAmount = res.Data
    },
    // 选择币种
    onConfirm(value) {
      this.BuyCurrency = value
      this.showPicker = false
      this.changeRate()
    },
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  width: 100%;
  padding: 60px 15px 0 15px;
  background: #fff;

  .content {
    height: 92px;
    background: #F6F6F6;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px;

    .account {
      width: 70%;

      .txt {
        display: flex;
        align-items: center;
        font-size: 13px;
        color: #999999;

        span {
          margin-left: 10px;
          font-weight: bold;
          font-size: 15px;
          color: #333333;
        }
      }

      .line {
        height: 1px;
        background: #E8E8E8;
        margin: 13px 0;
      }
    }

    .icoss {
      padding: 10px 6px;
      background: #FFFFFF;
      border-radius: 7px;
    }
  }

  .label {
    margin-top: 25px;
    font-weight: bold;
    font-size: 15px;
    color: #333333;
  }

  .coint {
    padding: 15px 0 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 15px;
    color: #333333;
    border-bottom: 1px solid #D2D2D2;
  }

  .msgs {
    margin-top: 10px;
    font-size: 13px;
    color: #999999;

    span {
      color: #333333;
    }
  }

}

.popInfo {
  .van-cell {
    background: #F2F2F2;
    padding: 10px;
    border-radius: 5px;
    font-size: 15px;
  }

  ::v-deep .van-password-input__security {
    height: 49px;
  }

  ::v-deep .van-password-input__security li {
    background: #E8E8E8;
    border-radius: 5px;
  }

  .title {
    padding: 20px 0;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #333333;
    border-bottom: 1px solid #DCDCDC;
  }

  .password {
    margin-top: 80px;
  }

  .pswname {
    margin: 20px 13px 0 13px;
    font-weight: bold;
    font-size: 16px;
    color: #333333;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .msg {
      font-weight: 500;
      font-size: 13px;
      color: #333333;

      span {
        color: #C92B2E;
      }

    }

  }

  .pwstips {
    margin-top: 15px;
    text-align: center;
    font-size: 14px;
    color: #999999;
  }

  .pwsbtn {
    margin: 60px 90px 150px 90px;
    font-size: 15px;
    color: #FFFFFF;
    height: 40px;
    background: #309C62;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .info {
    padding: 15px 13px 20px 13px;

    .name {
      font-weight: bold;
      font-size: 16px;
      color: #333333;
    }

    .price {
      margin-top: 10px;
      font-size: 14px;
      color: #333333;
    }

    .label {
      margin: 20px 0 10px 0;
      font-weight: bold;
      font-size: 14px;
      color: #333333;
    }

    .select {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      span {
        margin-bottom: 10px;
        width: 95px;
        height: 40px;
        background: rgba(235, 235, 235, 0);
        border-radius: 5px;
        border: 1px solid #CECECE;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        color: #333333;
      }

      .active {
        color: #fff;
        background: #C92B2E;
      }
    }

    .tips {
      font-size: 14px;
      color: #333333;
      text-transform: none;
    }

    .btn {
      margin: 60px 90px 0 90px;
      font-size: 15px;
      color: #FFFFFF;
      height: 40px;
      background: #309C62;
      border-radius: 25px;
      display: flex;
      justify-content: center;
      align-items: center;

    }
  }

  .result {
    text-align: center;
    padding: 45px 0 25px 0;

    .tit {
      margin-top: 10px;
      font-weight: bold;
      font-size: 20px;
      color: #333333;
      text-transform: none;
    }

    .txt {
      margin-top: 25px;
      font-size: 14px;
      color: #C92B2E;
    }

    .txt2 {
      margin-top: 25px;
      font-size: 14px;
      color: #999999;
    }

  }
}

::v-deep .van-popup__close-icon {
  color: #000000;
}

::v-deep .van-field__value {
  overflow: visible;
  display: grid;
}

::v-deep .van-button__content {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
}

.van-cell {
  border-bottom: 1px solid #D2D2D2;
  height: 50px;
  padding: 10px 0;
  font-size: 15px;
  color: #333333;
  font-weight: bold;
}
</style>
